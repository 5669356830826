<template>
  <div :class="$isWeb ? 'recently-h5' : 'recently'">
    <div class="content">
      <TableColumn :columns="columns" :data="data" />
    </div>
  </div>
</template>
<script>
import { getBlocks } from "../../libs/api.js";
const moment = require("moment");
import TableColumn from "@/components/layout/Table.vue";

export default {
  data() {
    const mobileColumns = [
      {
        key: "time",
        title: "Time",
        type: "text",
        width: 2.5,
        unit: "rem",
      },
      {
        key: "height",
        title: "Height",
        type: "a",
        width: 2.5,
        unit: "rem",
      },
      {
        key: "reward",
        title: "Reward",
        type: "text",
      },

      {
        key: "youReward",
        title: "Your reward",
        type: "text",
      },
    ];
    return {
      blocks: null,

      columns: this.$isWeb
        ? mobileColumns
        : [
            {
              key: "time",
              title: "Time",
              type: "text",
            },
            {
              key: "height",
              title: "Height",
              type: "a",
            },
            {
              key: "block_hash",
              title: "Hash",
              type: "a",
            },
            {
              key: "reward",
              title: "Reward",
              type: "text",
            },

            {
              key: "youReward",
              title: "Your reward",
              type: "text",
            },
          ],

      data: null,
    };
  },
  components: {
    TableColumn,
  },
  methods: {
    shortHash(hash_, count) {
      return hash_.slice(0, count) + "..." + hash_.slice(-count);
    },

    grinBlockLink(height) {
      return "https://grinscan.net/block/" + height;
    },

    grinBlockLink2(commit) {
      return "https://grin.blockscan.com/block/" + commit;
    },

    async getBlock() {
      try {
        let resp = await getBlocks();
        if (resp.data.length > 0) {
          this.blocks = resp.data.map((b) => {
            b.timestamp = moment(b.dt).format("MM-DD HH:mm");
            b.hash = this.shortHash(b.block_hash, 6);
            b.value_ = (b.value / 1000000000).toFixed(2);
            return b;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getBlock();
  },
  watch: {
    blocks: function(val) {
      let new_data = [];
      for (let b of val) {
        let h = this.grinBlockLink(b.height);
        let d = {
          time: b.timestamp,
          height: { href: h, value: b.height },
          block_hash: { href: h, value: b.hash },
          reward: b.value_ + " ツ",
          youReward: b.reward,
        };
        new_data.push(d);
      }
      this.data = new_data;
    },
  },
};
</script>
<style lang="less">
.recently {
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 8px; /*no*/
  padding: 50px 98px 0 108px; /*no*/
  width: 1120px; /*no*/
  // height: 800px;/*no*/
  // overflow: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15); /*no*/

  .desc {
    font-size: 16px; /*no*/
    font-weight: 400;
    line-height: 19px; /*no*/
    padding-top: 34px; /*no*/
    color: #888888;
    margin-bottom: 45px; /*no*/
  }
}
.recently-h5 {
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 8px;
  padding: 27px 60px;
  width: 626px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);

  .desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding-top: 34px;
    color: #888888;
    margin-bottom: 45px;
  }
}
</style>
