<template>
  <div :class="$isWeb ? 'home-card-h5' : 'home-card'">
    <div class="price-container">
      <div class="price-item1">
        <span class="avator">
            <img style="width: 100%" src="../../assets/img/home/smile.png" />
        </span>
        <span class="name">Grin</span>
      </div>
      <div class="price-item2">
        <span class="current_price">Current Price</span>
        <span class="price">{{ price }} $</span>
      </div>
    </div>
    <div class="price-detail">
      <div class="item1">
        <p class="item-title">Network Hashrate</p>
        <p class="item-value">{{ networkRate }} g/s</p>
      </div>
      <div class="item2">
        <p class="item-title">Pool Hashrate</p>
        <p class="item-value">{{ poolRate }} g/s</p>
      </div>
      <div class="item3">
        <p class="item-title">Pool Fee</p>
        <p class="item-value">1% PPLNS</p>
      </div>
    </div>
    <div class="price-circle">
      <div class="rect-box">
        <el-progress type="circle" :show-text="false" :stroke-width="16" :format="getPercent" class="card-circle" width="120" color="#893EFF" :percentage="percent" status="success"></el-progress>
        <div class="circle-percent">{{percent}}%</div>
      </div>
    </div>
  </div>
</template>
<script>
import OCircle from '@/components/layout/Circle.vue'

export default {
  props: {},
  data() {
    return {
    };
  },
  components: {
    OCircle
  },
  computed: {
    poolRate() {
      return this.$store.state.poolRate;
    },
    networkRate() {
      return this.$store.state.networkRate;
    },
    price() {
      return this.$store.state.grinPrice;
    },
    percent(){
      if (!this.$store.state.poolRate)return 0
      if(parseInt(this.$store.state.poolRate)===0)return 0
      return (parseFloat(this.$store.state.poolRate) * 100/parseFloat(this.$store.state.networkRate)).toFixed(2)
    }
  },
  methods: {
      getPercent(percentage) {
         return percentage
      }
  }
};
</script>
<style lang="less">
.home-card {
  position: relative;
  box-sizing: border-box;
  width: 1120px;/*no*/
  height: 438px;/*no*/
  background: #fff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);/*no*/
  background: url("../../assets/img/home/home_card_bg.png") no-repeat top center;
  padding: 0 110px;/*no*/
  margin: 0 auto;
  .price-container {
    height: 50px;/*no*/
    padding-top: 95px;/*no*/
    display: flex;
    align-items: center;
    position: relative;
    .price-item1{
        display: flex;
        align-items: center;
    }
    span {
      display: inline-block;
    }
    .avator {
      width: 50px;/*no*/
      height: 50px;/*no*/
    }
    .name {
      margin-left: 13px;/*no*/
      font-size: 27px;/*no*/
      font-weight: bold;
      line-height: 32px;/*no*/
      color: #101010;
    }
    .current_price {
      margin-left: 65px;/*no*/
      font-size: 18px;/*no*/
      font-weight: 500;
      line-height: 21px;/*no*/
      color: #676767;
    }
    .price {
      margin-left: 30px;/*no*/
      font-size: 22px;/*no*/
      font-weight: bold;
      line-height: 27px;/*no*/
      color: #101010;
    }
  }
  .price-detail {
    display: flex;
    position: relative;
    margin-top: 100px;/*no*/
    .item1 {
      position: absolute;
      left: 0;
    }
    .item2 {
      position: absolute;
      left: 250px;/*no*/
    }
    .item3 {
      position: absolute;
      left: 500px;/*no*/
    }
    .item-title {
      font-size: 18px;/*no*/
      font-weight: 600;
      line-height: 21px;/*no*/
      color: #666666;
    }
    .item-value {
      margin-top: 24px;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      color: #101010;
    }
  }

  .price-circle {
    position: absolute;
    height: 120px;/*no*/
    right: 130px;/*no*/
    bottom: 100px;/*no*/
    display: flex;
    align-items: center;
    .rect-box {
      position: relative;
      width: 120px;/*no*/
      height: 120px;/*no*/
      margin-left: 60px;/*no*/
      background-color: #fff;
      border-radius: 50%;
    }
    .info{
      font-size: 18px;/*no*/
    }
    .circle-percent{
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 16px;/*no*/
        margin-left: -16px;/*no*/
        margin-top: -8px;/*no*/
        color: #893EFF;
        font-weight: bold;
    }
    
  }
}
.home-card-h5 {
  position: relative;
  box-sizing: border-box;
  width: 625px;
  height: 416px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
  padding: 0 50px;
  margin: 0 auto;
  .price-container {
    padding-top:54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    .price-item1{
        display: flex;
        align-items: center;
    }
    .price-item2{
        display: flex;
       flex-direction: column;
       margin-top: 63px;
    }
    span {
      display: inline-block;
    }
    .avator {
      width: 51px;
      height: 51px;
    }
    .name {
      margin-left: 13px;
      font-size: 27px;
      font-weight: bold;
      line-height: 32px;
      color: #101010;
    }
    .current_price {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: #676767;
    }
    .price {
      margin-top: 21px;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      color: #101010;
    }
  }
  .price-detail {
    display: flex;
    position: relative;
    margin-top: 46px;
    .item1 {
      position: absolute;
      left: 0;
    }
    .item2 {
      position: absolute;
      left: 200px;
    }
    .item3 {
      position: absolute;
      left: 400px;
    }
    
    .item-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      color: #666666;
    }
    .item-value {
      margin-top: 24px;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      color: #101010;
    }
  }
  .price-circle {
    height: 120px;
    position: absolute;
    right: 76px;
    top: 120px;
    display: flex;
    align-items: center;
    .rect-box {
      position: relative;
      width: 120px;
      height: 120px;
      margin-left: 36px;
      background-color: #fff;
      border-radius: 50%;
    }
    .circle-percent{
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 16px;
        margin-left: -10px;
        margin-top: -8px;
        color: #893EFF;
        font-weight: bold;
    }
    .el-progress-circle{
        width: 120px !important;
        height: 120px !important;
    }
    .info{
      font-size: 20px;
    }
  }
}
</style>
