<template>
  <div :class="$isWeb ? 'calculator-h5' : 'calculator'">
    <p class="desc">Because the hashrate of the entire network changes in real time, the actual income will be a certain distance from the calculated 
Estimated Daily Revenu.</p>
    <div class="content">
        <TableColumn
          :columns="columns"
          :data = "data"
          :changeFn="onInputChange"
        />
    </div>
  </div>
</template>
<script>
import TableColumn from '@/components/layout/Table.vue'
import {poolFee} from '@/config'

export default {
    data() {
        return {
            columns: [
                {
                    key: 'input',
                    title: 'Your hashrate（g/s）',
                    type: 'input',
                },
                {
                    key: 'hashrate',
                    title: 'Networkd hashrate（g/s）',
                    type: 'text'
                },
                {
                    key: 'profitPerG',
                    title: 'Reward 1 g/s per day ',
                    type: 'text'
                },
                {
                    key: 'estimatedDailyRevenue',
                    title: 'Estimated Daily Reward',
                    type: 'text'
               }
            ],
            data: [
               {
                   input: '',
                   hashrate: '',
                   profitPerG: '',
                   estimatedDailyRevenue: '-- ツ'
               } 
            ]
        }
    },
    components: {
        TableColumn
    },

    methods: {
        getHashrate(){
            return this.$store.state.networkRate
        },
        getRewardPerG(){
            return (1 / this.getHashrate() * 86400 * (1-poolFee) ).toFixed(2)
        },
        onInputChange: function(e) {
            this.data[0]['estimatedDailyRevenue'] = (parseFloat(e) / this.getHashrate() * 86400 * (1-poolFee) ).toFixed(2) + ' ツ' 
        }
    },
    watch:{
        '$store.state.networkRate'(value, oldValue) {
            if(value > 0 ){
                this.data[0]['hashrate'] = value
                this.data[0]['profitPerG'] = (1 / value * 86400 ).toFixed(2) + ' ツ'
            }
        }
    },
    created(){
        this.data[0]['hashrate'] = this.$store.state.networkRate
        this.data[0]['profitPerG'] = (1 / this.$store.state.networkRate * 86400 ).toFixed(2) + ' ツ'
    },
}
</script>
<style lang="less">
    .calculator{
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0 98px 0 108px; /*no*/
        width: 1120px;/*no*/
        height: 262px;/*no*/
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);/*no*/
        .desc{
            font-size: 16px;/*no*/
            font-weight: 400;/*no*/
            line-height: 19px;/*no*/
            padding-top: 34px;/*no*/
            color: #888888;
            margin-bottom: 45px;/*no*/
        }
    }
    .calculator-h5 {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 30px 46px 42px 60px;
        width: 626px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
        .desc{
            margin-bottom: 45px;
            width: 520px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #888888;
            opacity: 1;
        }
    }
</style>