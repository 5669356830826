<template>
 <div :class="$isWeb ? 'tabs-h5' : 'tabs'">
   <div v-for="item in tabs" :class="['tab-item', item.isActive ? 'tab-item-active' : '']" @click="() => itemClick(item)">
      {{item.name}}
   </div>
 </div>
</template>
<script>
export default {
    props: {
        tabs: [],
        itemClick: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {}
    }
}
</script>
<style lang="less">
   .tabs {
       display: flex;
       width: 100%;
       justify-content: space-between;
       .tab-item {
           box-sizing: border-box;
           height: 40px; /*no*/
           line-height: 40px;/*no*/
           color: #888888;
           font-size: 16px;/*no*/
           font-weight: 500;
           width: 210px;/*no*/
           border: 1px solid #893EFF;
           text-align: center;
           
       }
       .tab-item-active {
           background-color: #893EFF;
           color: #fff;
       }
   } 
    .tabs-h5 {
       display: flex;
       width: 100%;
       justify-content: space-between;
       flex-wrap: wrap;
       .tab-item {
           box-sizing: border-box;
           height: 44px;
           line-height: 44px;
           color: #888888;
           font-size: 18px;
           font-weight: 500;
           width: 238px;
           border: 1px solid #893EFF;/*no*/
           text-align: center;
           margin-bottom: 27px;
           
       }
       .tab-item-active {
           background-color: #893EFF;
           color: #fff;
       }
   } 
</style>