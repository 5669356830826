import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home'

Vue.use(VueRouter)
const isdev = process.env.NODE_ENV === "development"
// const isdev = false
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nav',
    name: 'Nav',
    component: () => import('../views/MobileNav'),
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal'),
    meta: { requiresAuth: !isdev}
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/My'),
    meta: { requiresAuth: !isdev}
  },
  {
    path: '/login/:type',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.logined){
      next()
      return
    }
    next('/') 
  }else{
    next() 
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router