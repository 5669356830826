import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './libs/flexible'

import('./assets/css/base.css')

import http from './libs/http'
Vue.prototype.$http = http
import { messageBus } from './libs/messagebus'
Vue.prototype.$messageBus = messageBus

Vue.prototype.$isWeb = false
if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
  Vue.prototype.$isWeb = true
} 

import { Dialog, Message, Button, Progress, Input, Table, TableColumn, Dropdown, DropdownMenu, DropdownItem} from 'element-ui';
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Progress)
Vue.use(Input)
Vue.use(Table)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.prototype.$message = Message

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

const token = localStorage.getItem('token')
if(token){
  store.commit('updateToken', token)
}
//if (token){
// store.dispatch('refresh', token).catch(()=>{})
//}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')