<template>
<div :class="$isWeb ? 'pool-h5': 'pool'">
  <Tabs 
    :tabs="tabs"
    :itemClick="tabItemClick"
  />
  <p class="desc">{{server}}</p>
</div>
</template>
<script>
import Tabs from '@/components/layout/Tabs.vue'

export default {
    components: {
        Tabs
    },
    data() {
        return {
            server: 'stratum+tcp://grin32.asia.gaeapool.com:3344',
            servers: {
                //'China Mainland': 'stratum+tcp://grin32.cn.gaeapool.com:3344',
                'Asia': 'stratum+tcp://grin32.asia.gaeapool.com:3344',//'stratum+tcp://grin32.hk.gaeapool.com:3344',
                'Europe': 'stratum+tcp://grin32.eu.gaeapool.com:3344',//'stratum+tcp://grin32.eu.gaeapool.com:3344',
                'America': 'stratum+tcp://grin32.us.gaeapool.com:3344',//'stratum+tcp://grin32.us.gaeapool.com:3344',
            },
            tabs: [
                {
                    name: 'Asia',
                    isActive: true
                },
                {
                    name: 'Europe',
                    isActive: false
                },
                {
                    name: 'America',
                    isActive: false
                }
            ]
        }
    },
    methods: {
        tabItemClick(item) {
            this.tabs.forEach((ele) => {
                if (item.name === ele.name) {
                    this.server = this.servers[ele.name]
                    ele.isActive = true;
                } else {
                    ele.isActive = false
                }
            })
        }
    }
}
</script>
<style lang="less">
    .pool{
        margin: 0 auto;

        width: 1120px; /*no*/
        box-sizing: border-box;
        padding: 54px 110px;/*no*/
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);/*no*/
        .desc{
            font-size: 16px;/*no*/
            font-weight: bold;
            line-height: 19px;/*no*/
            color: #888888;
            text-align: center;
            margin-top: 45px;/*no*/
        }
    }
    .pool-h5{
        margin: 0 auto;
        width: 625px;
        box-sizing: border-box;
        padding: 46px 60px;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
        .desc{
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            color: #888888;
            text-align: center;
            margin-top: 29px;
        }
    }
    
</style>
