var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$isWeb ? 'table-container-h5' : 'table-container'},[_c('table',[_c('thead',{staticClass:"thead"},[_c('tr',_vm._l((_vm.columns),function(item){return _c('th',{class:[
            'th',
            _vm.showBorder ? 'border-th' : '',
            item.active ? 'th-active' : '' ],style:({
            width: item.width
              ? item.width + (item.unit ? item.unit : 'px')
              : 'auto',
          })},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',_vm._l((_vm.keys),function(ele){return _c('td',{class:[_vm.showBorder ? 'border-td' : '']},[(_vm.getPropertity(ele).type === 'input')?_c('div',{staticClass:"td",staticStyle:{"display":"flex","justify-content":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item[ele]),expression:"item[ele]"}],staticClass:"td-input",domProps:{"value":(item[ele])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, ele, $event.target.value)},function($event){return _vm.changeFn(item[ele], ele)}]}})]):(_vm.getPropertity(ele).type === 'a')?_c('a',{staticClass:"td",attrs:{"href":item[ele].href}},[_vm._v(" "+_vm._s(item[ele].value))]):_c('span',{staticClass:"td"},[_vm._v(" "+_vm._s(item[ele]))])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }