<template>
  <div id="app">
    <Header v-if="!notShowHeader"></Header>
    <router-view/>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/layout/Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  },
  async created () {
    await this.$store.dispatch('updateRate')
    await this.$store.dispatch('updateGrinPrice')
  },
  computed: {
    notShowHeader() {
      return ['Login', 'Nav'].includes(this.$route.name) && this.$isWeb
    }
  }
}
</script>