<template>
  <div :class="$isWeb ? 'header-h5' : 'header'">
    <template v-if="!$isWeb">
      <img
        src="../../assets/img/home/logo@2x.png"
        @click="() => this.$router.push('/')"
        class="logo"
      />
      <div class="header-nav">
        <router-link class="nav" to="/">PooL</router-link>

        <router-link class="nav" to="/my" v-if="hasLogin">Home</router-link>
        
        <a class="nav" href="https://docs.google.com/document/d/1fkJQ1BRT1eU1yptm_nLSyA5qnXgd7hxL-rltCsQq6UI/edit"
           target="_blank">Tutorial</a>
        
        <a class="nav" href = "mailto: gaeapool@gmail.com">gaeapool@gmail.com</a>

        <!--
        <el-dropdown class="dropdown" placement="bottom-start">
          <span class="nav">
            More<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
         
          <el-dropdown-menu  slot="dropdown">
             
           <el-dropdown-item>
              <div
                class="nav"
              >qqgroup636081273</div
             ></el-dropdown-item>
          
          <el-dropdown-item>
              <a
                class="nav"
                href="https://t.me/joinchat/C98Yl7Bjo8M2ZGI1"
                target="_blank"
              >TelegramLink</a
             ></el-dropdown-item>
             
            <el-dropdown-item>
              <a
                class="nav"
                href="https://docs.qq.com/doc/DYkpZZXB5Z3ZvdXBl"
                target="_blank"
              >CHN-Tutorial</a
             ></el-dropdown-item>
             
             <el-dropdown-item>
              <a
                class="nav"
                href="https://docs.google.com/document/d/1fkJQ1BRT1eU1yptm_nLSyA5qnXgd7hxL-rltCsQq6UI/edit"
                target="_blank"
              >Tutorial</a
             ></el-dropdown-item>
             
          </el-dropdown-menu>
        </el-dropdown>
        -->
      </div>
      <div class="login" v-if="hasLogin">
        <span class="username">{{ userName }}</span>
        <span class="btn btn-default" @click="logout">logout</span>
      </div>
      <div class="login" v-else>
        <span class="btn btn-noborder mr13" @click="gotoLogin">Log in</span>
        <span class="btn btn-default" @click="gotoSignUp">Sign Up</span>
      </div>
    </template>
    <template v-else>
      <img
            src="../../assets/img/home/logo-mobile.png"
            @click="() => this.$router.push('/')"
            class="logo-mobile"
        />
        <div class="option-select" @click="optionSelect"></div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //hasLogin: false,
      //userName: '',
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login/signIn");
    },
    gotoSignUp() {
      this.$router.push("/login/signUp");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    optionSelect() {
        this.$router.push('/nav')
    }
  },
  computed: {
    hasLogin() {
      return this.$store.getters.logined;
    },
    userName() {
      return this.$store.username;
    },
  },
};
</script>
<style lang="less">
.header {
  display: flex;
  justify-content: space-between;
  background-color: #101010;
  .logo {
    width: 146px; /* no */
    height: 36px; /* no */
    margin-left: 40px; /* no */
    margin-top: 28px; /* no */
    cursor: pointer;
  }

  .option.png {
  }
  .header-nav {
    padding-top: 35px; /* no */
    font-size: 15px; /*no*/
    line-height: 19px;  /*no*/
  }
  .dropdown{

  }
  .nav {
    font-size: 15px; /* no */
    padding: 0 25px; /* no */
    color: #fff;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    &:hover {
      color: #893eff;
    }
  }
  .router-link-exact-active {
    color: #893eff;
  }

  .login {
    color: #fff;
    margin-top: 30px; /* no */
    margin-right: 40px; /* no */
    .btn {
      font-size: 15px; /* no */
      padding: 5px 11px; /* no */
      cursor: pointer;
      &:hover {
        color: #893eff;
      }
    }
    .btn-default {
      border: 1px solid #893eff;
    }
  }
  .btn-noborder {
    color: #fff;
  }
  .mr13 {
    margin-right: 13px; /* no */
  }
}
.header-h5 {
  display: flex;
  justify-content: space-between;
  background-color: #101010;
  margin-bottom: -2px;
  .logo-mobile {
    width: 45px;
    height: 63px;
    margin-left: 40px;
    margin-top: 40px;
  }
  .option-select {
    margin-top: 60px;
    margin-right: 40px;
    width: 37px;
    height: 32px;
    background: url("../../assets/img/home/option.png") no-repeat center center;
    background-size: cover;
  }
}
</style>
