import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {baseURL, loginURL, refreshURL} from '@/config'
import router from '../router'
import {getGrinAddress, getBalance, getRate, getGrinPrice} from '../libs/api.js'

Vue.use(Vuex)

let client = axios.create({
  baseURL: baseURL
});
export default new Vuex.Store({
  state: {
    token: '',
    username: '',
    grinPrice: '--',

    openEmail: false,
    openEmail4Verify: false,
    emailVerifyPurpose: '',
    openSlatepack: false,
    openSlatepack2: false,

    errors: [],
    infos: [],
    grinAddress: '',
    
    //balance
    total: '--',
    available: '--',
    locked: '--',

    //slatepack: {id: xxx, s1: xxxx, s2 xxx}
    slatepack: null,

    poolRate: null,
    networkRate: null,

  },
  getters:{
    logined: state =>{
      return state.token != ''
    }
  },
  mutations: {
    updateToken(state, token){
      state.token = token
    },
    removeToken(state){
      state.token = ''
    },
    setUsername(state, username){
      state.username = username
    },
    removeUsername(state){
      state.username = ''
    },
    setRate(state, rate){
      state.poolRate = rate.poolRate
      state.networkRate = rate.networkRate
    },
    setGrinPrice(state, price){
      state.grinPrice = price
    },
    setGrinAddress(state, address){
      state.grinAddress = address
    },

    setSlatepack(state, slatepack){
      state.slatepack = slatepack
    },

    setBalance(state, balance){
      state.total = balance.total
      state.locked = balance.locked
      state.available =  balance.available
      state.withdrawed =  balance.withdrawed
    },

    openEmail(state){
      state.openEmail = true
    },
    closeEmail(state){
      state.openEmail = false
    },
    
    openEmail4Verify(state, purpose){
      state.openEmail4Verify = true
      state.emailVerifyPurpose = purpose
    },
    closeEmail4Verify(state){
      state.openEmail4Verify= false
      state.emailVerifyPurpose = ''
    },

    openSlatepack(state){
      state.openSlatepack = true
    },
    closeSlatepack(state){
      state.openSlatepack = false
    },
    openSlatepack2(state){
      state.openSlatepack2 = true
    },
    closeSlatepack2(state){
      state.openSlatepack2 = false
    },

    addError(state, error){
      let err = {
        'createdAt': Date.now(),
        'text': error
      }
      state.errors.push(err)
    },
    removeError(state, createdAt){
      state.errors = state.errors.filter(e=>e.createdAt!==createdAt)
    },
    removeExpiredErrors(state, time){
      state.errors = state.errors.filter(e=>(Date.now()-e.createdAt) <= time*1000)
    },

    addInfo(state, info){
      let info_ = {
        'createdAt': Date.now(),
        'text': info
      }
      state.infos.push(info_)
    },
    removeInfo(state, createdAt){
      state.infos = state.infos.filter(i=>i.createdAt!==createdAt)
    },
    removeExpiredInfos(state, time){
      state.infos = state.infos.filter(i=>(Date.now()-i.createdAt) <= time*1000)
    },
    
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        client.post(loginURL, user).then(resp =>{
            //console.log(resp.data)
            const token = resp.data.token
            //console.log('token after login:' + token)
            commit('updateToken', token)
            localStorage.setItem('token', token)
            localStorage.setItem('username', user.username)
            resolve(resp)
          }).catch(err => {
            commit('removeToken')
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            reject(err)
          })
      })
    },
    refresh({commit}, token){
      return new Promise((resolve, reject) => {
        client.post(refreshURL, {'token': token}).then(resp =>{
            const token = resp.data.token
            //console.log('token after refresh:' + token)
            commit('updateToken', token)
            localStorage.setItem('token', token)

            const username = localStorage.getItem('username')
            commit('setUsername', username)
            resolve(resp)
          }).catch(err => {
            commit('removeToken')
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            reject(err)
        })
      })
    },
    logout({commit}){
      commit('removeToken')
      commit('removeUsername')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      router.push('/')
    },
    async getGrinAddress({commit}){
      try{
        let resp = await getGrinAddress()
        if(resp.data.grin_address){
          commit('setGrinAddress', resp.data.grin_address)
        }else{
          commit('setGrinAddress', '')
        }
      }catch(error){
        console.log('getGrinAddress:', error)
      }
    },

    async updateBalance({commit}){
      try{
        let resp = await getBalance()
        if(resp.data){
          commit('setBalance', resp.data)
        }
      }catch(error){
        console.log('updateBalance:', error)
      }
    },

    async updateGrinPrice({commit}){
      try{
        let resp = await getGrinPrice()
        if(resp.data){
          commit('setGrinPrice', resp.data)
        }
      }catch(error){
        console.log('updateGrinPrice:', error)
      }
    },

    async updateRate({commit}){
      try{
        let resp = await getRate()
        if(resp.data){
          let rate = {
            'poolRate': parseFloat(resp.data.pool).toFixed(2),
            'networkRate':parseFloat(resp.data.network).toFixed(2)
          }
          commit('setRate', rate)
        }
      }catch(error){
        console.log('updateRate:', error)
      }
    }
  },
  modules: {
  }
})
