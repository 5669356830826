import client from './http'

export async function getStats(range) {
    return client.get(`/api/stats2/?range=${range}`)
}

export async function getSummary() {
    return client.get('/api/summary/')
}

export async function getRigstats() {
    return client.get('/api/rigstats/')
}

export async function getBlocks() {
    return client.get('/api/block2/')
}

export async function getEmail() {
    return client.get('/api/email/')
}

export async function getCodeToBindEmail(email) {
    let data = {
        'action': 'get_code',
        'purpose': 'bind_email',
        'email': email
    }
    return client.post('/api/email/', data)
}

export async function verifyCode(code) {
    let data = {
        'action': 'verify',
        'code': code
    }
    return client.post('/api/email/', data)
}

export async function getGrinAddress(exchange = null) {
    if (exchange) {
        return client.get(`/api/grin_address/?exchange=${exchange}`)
    }
    return client.get('/api/grin_address/')
}

export async function getCodeToBindGrinAddress(address) {
    let data = {
        'action': 'get_code',
        'purpose': 'bind_grin_address',
        'grin_address': address
    }
    return client.post('/api/email/', data)
}

export async function getCodeToBindHTTPGrinAddress(address, exchange) {
    let data = {
        'action': 'get_code',
        'purpose': 'bind_http_grin_address',
        'grin_address': address,
        'exchange': exchange
    }
    return client.post('/api/email/', data)
}

export async function getCodeToBindGateGrinAddress(address, memo) {
    let data = {
        'action': 'get_code',
        'purpose': 'bind_gate_grin_address',
        'gate_memo': memo,
        'gate_grin_address': address
    }
    return client.post('/api/email/', data)
}

export async function getCodeToWithdraw(amount) {
    let data = {
        'action': 'get_code',
        'purpose': 'withdraw',
        'amount': amount
    }
    return client.post('/api/email/', data)
}

export async function getSlatepack(amount) {
    let data = {
        'step': 'init_send',
        'amount': amount
    }
    return client.post('/api/withdraw/', data)
}

export async function finalizeTx(tx_slate_id, slatepack) {
    let data = {
        'step': 'finalize',
        'slatepack': {
            'tx_slate_id': tx_slate_id,
            'slatepack_s2': slatepack
        }
    }
    return client.post('/api/withdraw/', data)
}


export async function withdraw2excahnge(amount, exchange) {
    let data = {
        'exchange': exchange,
        'amount': amount
    }
    return client.post('/api/withdraw2/', data)
}

export async function getWithdrawHistory() {
    return client.get('/api/withdraw_history/')
}

export async function getBalance() {
    return client.get('/api/balance/')
}

export async function getRate() {
    return client.get('/api/rate/')
}

export async function getReward(tz) {
    if (tz === 'BJT') {
        return client.get('/api/reward/')
    }
    return client.get(`/api/reward/?tz=${tz}`)
}

export async function deleteGrinAddress(exchange = '') {
    return client.post('/api/grin_address/', {'exchange': exchange})
}

export async function getObserverData(userName = '') {
    return client.get(`/api/observer/${userName}`)
}

export async function getGrinPrice(){
    return client.get(`/api/price/?format=json`)
}