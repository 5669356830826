<template>
  <div :class="{ pageTitle: !$isWeb, 'h5-pageTitle': $isWeb }">
    {{ this.title }}<span class="icon" />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="less">
.pageTitle {
  margin: 80px auto 8px; /*no*/
  padding: 0 108px; /*no*/
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 1120px; /*no*/
  height: 90px; /*no*/
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15); /*no*/
  font-size: 24px; /*no*/
  font-weight: bold;
  color: #101010;
  .icon {
    margin-left: 10px; /*no*/
    width: 22px; /*no*/
    height: 22px; /*no*/
    background: url("../../assets/img/home/smile.png") no-repeat center center;
    background-size: cover;
  }
}
.h5-pageTitle {
  width: 750px;
  margin: 40px auto 12px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 626px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.1);
  font-size: 20px;
  font-weight: bold;
  color: #101010;
  .icon {
    margin-left: 12px;
    width: 18px;
    height: 18px;
    background: url("../../assets/img/home/smile.png") no-repeat center center;
    background-size: cover;
  }
}
</style>
