//export const baseURL = 'http://api.goblinpool.com:8080'
//export const baseURL = 'http://143.198.98.217:8000'

export const baseURL = 'https://api.gaeapool.com'
//export const baseURL = 'http://127.0.0.1:8000'
export const loginURL = '/api/auth/login/'
export const registerURL = '/api/auth/register/'
export const refreshURL = '/api/auth/refresh/'

//grin withdraw
export const minWithdrawAmount = 5
export const withdrawFee = 0.1
export const poolFee = 0

//canWithdraw: use slatepack manually
export const canWithdraw = true
export const canWithdraw2 = true
export const canWithdraw2gate = true
export const canWithdraw2hotbit = true
export const canWithdraw2bitforex = true
