<template>
  <div :class="$isWeb ? 'table-container-h5' : 'table-container'">
    <table>
      <thead class="thead">
        <tr>
          <th
            v-for="item in columns"
            :class="[
              'th',
              showBorder ? 'border-th' : '',
              item.active ? 'th-active' : '',
            ]"
            :style="{
              width: item.width
                ? item.width + (item.unit ? item.unit : 'px')
                : 'auto',
            }"
          >
            {{ item.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data">
          <td v-for="ele in keys" :class="[showBorder ? 'border-td' : '']">
            <div
              class="td"
              v-if="getPropertity(ele).type === 'input'"
              style="display: flex; justify-content: center"
            >
              <input
                class="td-input"
                v-model="item[ele]"
                @input="changeFn(item[ele], ele)"
              />
            </div>
            <a
              class="td"
              v-else-if="getPropertity(ele).type === 'a'"
              :href="item[ele].href"
            >
              {{ item[ele].value }}</a
            >
            <span class="td" v-else> {{ item[ele] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    columns: [],
    data: [],
    changeFn: {
      type: Function,
      default: () => {},
    },
    showBorder: false,
  },
  computed: {
    keys() {
      return this.columns.map((item) => item.key);
    },
  },
  methods: {
    getPropertity(key) {
      return this.columns.find((item) => item.key === key);
    },
  },
};
</script>
<style lang="less">
.table-container {
  table {
    width: 100%;
  }
  .th {
    height: 19px; /*no*/
    font-size: 16px; /*no*/
    font-weight: bold;
    line-height: 19px; /*no*/
    border-bottom: 1px solid #ebeef5;
    padding: 12px 0; /*no*/
  }
  .border-th {
    border: 1px solid #893eff;
    font-size: 16px; /*no*/
    font-weight: 500;
    line-height: 19px; /*no*/
    color: #fff;
    color: #893eff;
  }
  .th-active {
    background-color: #893eff;
    font-size: 16px; /*no*/
    font-weight: 500;
    line-height: 19px; /*no*/
    color: #fff;
  }
  .td {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 17px 0; /*no*/
  }
  .border-td {
    border: 1px solid #cccccc;
  }
  .td-input {
    box-sizing: border-box;
    width: 211px; /*no*/
    height: 40px; /*no*/
    padding: 10px 12px; /*no*/
    font-size: 16px; /*no*/
    border: 1px solid #b7b7b7;
  }
  .thead {
    padding: 12px 0; /*no*/
  }
}
.table-container-h5 {
  width: 100%;
  // overflow: scroll;
  overflow-x: auto;
  table {
    width: 100%;
  }
  .th {
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    // line-height: 20px;
    border-bottom: 1px solid #ebeef5;
    // margin-right: 30px;
    text-align: left;
    // transform: scale(0.7);
    padding: 12px 0;
  }
  .border-th {
    border: 1px solid #893eff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #893eff;
  }
  .th-active {
    background-color: #893eff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #fff;
  }
  .td {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 22px 0;
    min-width: 120px;
    text-align: left;
  }
  .border-td {
    border: 1px solid #cccccc;
  }
  .td-input {
    box-sizing: border-box;
    width: 118px;
    height: 26px;
    padding: 8px 0;
    font-size: 12px;
    line-height: 22px;
    border: 1px solid #b7b7b7;
  }
  .thead {
    padding: 12px 0;
  }
}
</style>
