<template>
  <div :class="{'home-h5': $isWeb , 'home': !$isWeb }">
    <Banner :space="30" />
    <div class="home-container">
      <div class="card-container"><Card /></div>
      <PageTitle title="Pool server"/>
      <Pool />
      <PageTitle title="Calculator"/>
      <Calculator />
      <PageTitle title="Grin blocks recently mined by GaeaPooL" v-if="!logined" />
      <Recently v-if="!logined" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from '@/components/layout/Banner.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import Footer from '@/components/layout/Footer.vue'

import Card from './Card.vue'
import Calculator from './Calculator'
import Recently from './Recently'
import Pool from '../My/Pool'

export default {
  name: 'Home',
  components: {
    Banner,
    Card,
    PageTitle,
    Calculator,
    Recently,
    Footer,
    Pool
  },
  computed: {
    logined() {
      return this.$store.getters.logined
    },
  },
  watch: {
    logined: function (val) {
      if(val)this.$router.push('/my')
    }
  },
}
</script>
<style lang="less" scoped>
  .home{
    width: 100%;
    .home-container{
      position: relative;
      padding-top: 254px; /*no*/
      min-width: 1120px;/*no*/
      padding-bottom: 90px;/*no*/
      .card-container{
        position: absolute;
        top: -160px;/*no*/
        left: 0;
        right: 0;
        z-index: 9;
        margin: 0 auto;
      }
    }
  }
  
  .home-h5{
    width: 100%;
    .home-container{
      position: relative;
      padding-top: 376px;
      padding-bottom: 90px;
      .card-container{
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
        z-index: 9;
        margin: 0 auto;
      }
    }
  }
</style>
