<template>
  <div :class="$isWeb ? 'banner-h5' : 'banner'">
    <div class="title">
      <p style="text-align: left">Hello, {{ username }}</p>
      <p>Welcome to GAEA Pool 👋</p>
      <div class="btns" v-if="$isWeb && !$store.state.username">
        <span class="btn btn-success" @click="signUp">Sign up</span>
        <span class="btn" @click="logIn">Log in</span>
      </div>
    </div>
    <div class="bgimg"></div>
  </div>
</template>
<script>
export default {
  computed: {
    username() {
      if (this.$store.state.username) {
        return this.$store.state.username;
      } else {
        return "Miner";
      }
    },
  },
  methods: {
    signUp() {
      this.$router.push("/login/signUp");
    },
    logIn() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less">
.banner {
  box-sizing: border-box;
  height: 711px;
  width: 100%;
  position: relative;
  background-color: #101010;
  .title {
    width: 400px; /*no*/
    padding-top: 120px; /*no*/
    color: #fff;
    font-size: 30px; /*no*/
    font-weight: bold;
    line-height: 36px; /*no*/
    color: #ffffff;
    text-align: left;
    margin: 0 auto;
  }
  .h5-title {
    padding-top: 116px;
    width: 500px;
    height: 104px;
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: -1px;
  }
  .bgimg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 594px;
    height: 430px;
    background: url("../../assets/img/login/login_left_img.png") no-repeat top
      center;
    background-size: cover;
  }
}
.banner-h5 {
  box-sizing: border-box;
  height: 577px;
  width: 100%;
  position: relative;
  background-color: #101010;
  .title {
    padding-top: 116px;
    width: 520px;
    height: 104px;
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: -1px;
    text-align: left;
    margin: 0 auto;
  }
  .btns {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .btn {
      display: inline-block;
      width: 229px;
      height: 54px;
      opacity: 1;
      color: #fff;
      font-size: 20px;
      line-height: 54px;
      text-align: center;
      border: 1px solid #893eff; /*no*/
    }
    .btn-success {
      background: #893eff;
    }
  }
  .bgimg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 472px;
    height: 254px;
    background: url("../../assets/img/login/login_left_img.png") no-repeat top
      center;
    background-size: cover;
  }
}
</style>
